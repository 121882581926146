import IExpertiseData from "../../interfaces/IExpertiseData";

const ExpertiseData: IExpertiseData[] = [
  {
    expertiseTitle: "Software Engineering",
    description:
      "Enterprise-level experience implementing API and web applications with Monolith or Microservices architectures. Familiar with SOLID principles and CLEAN architecture, particularly Single Responsible Priciple & Depency Injection.",
    descriptionHighlight: undefined,
    graphicPath: "/expertise/software-development.png",
  },
  {
    expertiseTitle: "Frontend Dev",
    description:
      "1.5+ years of enterprise-level front-end experience using old & new frameworks (Vue2, Vue3, Angular). Preference for Typescript for code readibility.",
    descriptionHighlight:
      "Meticulous about Responsiveness - Try on Mobile/Resize this site!",
    graphicPath: "/expertise/frontend-development.png",
  },
  {
    expertiseTitle: "Web Design",
    description:
      "Through a side hobby, I have experience designing simple and modern websites for small businesses, informed by UX/UI principles.",
    descriptionHighlight: undefined,
    graphicPath: "/expertise/web-design.png",
  },
];

export default ExpertiseData;
