import IHardAndSoftSkill from "../../interfaces/IHardAndSoftSkill";

const HardAndSoftSkillsData: IHardAndSoftSkill[] = [
  {
    proficiencyTitle: "Expert",
    technolgies: [
      "JavaScript",
      "TypeScript",
      "C#",
      "Dotnet",
      "API",
      "Vue 3",
      "HTML",
      "CSS",
    ],
  },
  {
    proficiencyTitle: "Proficient",
    technolgies: [
      "SOLID",
      "CLEAN",
      "Docker",
      "Unit Testing (backend)",
      "Angular",
      "React",
    ],
  },
  {
    proficiencyTitle: "Intermediate",
    technolgies: [
      "AWS",
      "Azure",
      "SQL",
      "Python",
      "ReactNative",
      "Express",
      "MongoDB",
      "PHP",
    ],
  },
  {
    proficiencyTitle: "Soft Skill Highlights",
    technolgies: [
      "Clear Communicator",
      "Empathetic & Respectful",
      "Open-minded",
      "Feedback-seeking",
      "Quick Learner",
      "Time Management",
      "Accountable",
      "Effective Collaborator",
    ],
  },
];

export default HardAndSoftSkillsData;
