import { IProjectFilterCategory } from "../../interfaces/IProjectFilterCategory";

const ProjectFilterCategoriesData: IProjectFilterCategory[] = [
  {
    categoryName: "All",
    categoryId: "all",
  },
  {
    categoryName: "Software Development",
    categoryId: "software",
  },
  {
    categoryName: "Web Design and Development",
    categoryId: "web",
  },
  {
    categoryName: "Mobile Development",
    categoryId: "mobile",
  },
];

export default ProjectFilterCategoriesData;
